import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { GoCircle } from 'react-icons/go';
import { Link } from 'react-router-dom';

function Cart3() {
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [loading, setLoading] = useState(true); 
  const [error, setError] = useState(null); 

  useEffect(() => {
    const fetchPaymentDetails = async () => {
      try {
        const response = await axios.post('http://localhost:3050/post/payment-option');
        setPaymentDetails(response.data);
        setLoading(false);
      } catch (error) {
        console.error('There was an error fetching the payment details!', error);
        setError('Failed to load payment details. Please try again later.');
        setLoading(false);
      }
    };

    fetchPaymentDetails();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  return (
    <div className="flex flex-col pb-8 min-h-screen bg-gray-50">
      <main className="flex-1">
        <div className="border-b-2 border-gray-300 px-6 py-4 mb-6 bg-white shadow-sm">
          <ol className="flex justify-between text-sm md:text-base">
            <li className="flex items-center">
              <GoCircle className="text-red-500 mr-2" />
              <span className="font-semibold text-red-500">Cart</span>
            </li>
            <li className="flex items-center text-gray-400">
              <GoCircle className="text-red-500 mr-2" />
              <span className="font-semibold text-red-500">Checkout</span>
            </li>
            <li className="flex items-center text-gray-400">
              <GoCircle className="text-gray-400 mr-2" />
              <span>Successful</span>
            </li>
          </ol>
        </div>

        <div className="flex justify-center items-center mb-12">
          <Link to="/cart" className="text-xl text-gray-700 underline hover:text-red-500 transition duration-300">
            Back
          </Link>
        </div>

        <div className="container mx-auto px-4 flex flex-col md:flex-row justify-between space-y-8 md:space-y-0 md:space-x-8">
          <div className="bg-white shadow-lg p-8 rounded-lg flex flex-col items-center md:w-1/2">
            <h1 className="font-semibold text-xl mb-6 text-gray-700 text-center">Payment</h1>
            <div className="flex justify-center mb-6">
              {paymentDetails.qr_code && (
                <img src={paymentDetails.qr_code} alt="QR code" className="w-40 h-40 rounded-lg shadow-md" />
              )}
            </div>
            <div className="w-full">
              <label className="block mb-2 text-gray-600">Submit your payment receipt</label>
              <input type="file" name="qr_code" className="mb-4 w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-red-500" />
              <button className="w-full px-6 py-3 bg-red-500 text-white rounded-lg hover:bg-red-600 transition duration-300">Submit</button>
            </div>
          </div>

          <div className="bg-white shadow-lg p-8 rounded-lg md:w-1/2">
            <h1 className="text-center text-gray-700 font-semibold text-xl mb-6">Bank Details</h1>
            {paymentDetails.bankName && <p><strong>Bank Name:</strong> {paymentDetails.bankName}</p>}
            {paymentDetails.accountNo && <p><strong>Account No.:</strong> {paymentDetails.accountNo}</p>}
            {paymentDetails.ifscCode && <p><strong>IFSC Code:</strong> {paymentDetails.ifscCode}</p>}
            {paymentDetails.accountHolderName && <p><strong>Account Holder Name:</strong> {paymentDetails.accountHolderName}</p>}
            {paymentDetails.branchName && <p><strong>Branch Name:</strong> {paymentDetails.branchName}</p>}
            {paymentDetails.upiId && <p><strong>UPI ID:</strong> {paymentDetails.upiId}</p>}
          </div>
        </div>
      </main>
    </div>
  );
}

export default Cart3;
